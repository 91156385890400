<template>
  <div
    class="flex flex-wrap justify-center bg-background-extra-light"
    :class="{ 'p-7 lg:p-4': partners?.length }"
  >
    <a
      v-for="partner in partners"
      :key="partner.alt"
      :href="partner.url"
      rel="noopener noreferrer nofollow"
      target="_blank"
    >
      <nuxt-img
        class="m-3 h-[30px] w-auto object-contain lg:m-6 lg:h-[50px]"
        loading="lazy"
        :alt="partner.alt"
        :src="logoSrc(partner.logo as keyof typeof logos)"
      />
    </a>
  </div>
</template>

<script setup lang="ts">
import ApplePay from '~/assets/logos/apple-pay.svg?url';
import GooglePay from '~/assets/logos/google-pay.svg?url';
import Paypal from '~/assets/logos/paypal.svg?url';
import Visa from '~/assets/logos/visa.svg?url';
import Mastercard from '~/assets/logos/mastercard.svg?url';
import Amex from '~/assets/logos/amex.svg?url';
import JapanCreditBureau from '~/assets/logos/japan-credit-bureau.svg?url';
import Diners from '~/assets/logos/diners.svg?url';
import Discover from '~/assets/logos/discover.svg?url';
import ChinaUnionpay from '~/assets/logos/china-unionpay.svg?url';
import Klarna from '~/assets/logos/klarna.svg?url';
import Postnord from '~/assets/logos/postnord.svg?url';
import Budbee from '~/assets/logos/budbee.svg?url';
import Bankid from '~/assets/logos/bankid.svg?url';

const logos = {
  'apple-pay.svg': ApplePay,
  'google-pay.svg': GooglePay,
  'paypal.svg': Paypal,
  'visa.svg': Visa,
  'mastercard.svg': Mastercard,
  'amex.svg': Amex,
  'japan-credit-bureau.svg': JapanCreditBureau,
  'diners.svg': Diners,
  'discover.svg': Discover,
  'china-unionpay.svg': ChinaUnionpay,
  'klarna.svg': Klarna,
  'postnord.svg': Postnord,
  'budbee.svg': Budbee,
  'bankid.svg': Bankid,
};

const logoSrc = (logo: keyof typeof logos) => logos[logo];

const { partners } = useShopCountryConfig();
</script>
